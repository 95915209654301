<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
        <div class="flex items-center text-lg font-semibold dark:text-gray-300">
            <Button icon="arrow-left" @click="goBack" />
            <template v-if="catalogItemUnit">
              <h2>{{catalogItemUnit.catalog_item.name}}</h2>
            </template>
        </div>
    </template>
    
    <template v-slot:content>
        <div>
            <div class="sm:rounded-md mb-3">
                <ul role="list" class="">
                    <template v-if="catalogItemUnit">
                        <CatalogItemUnitDetails :item="catalogItemUnit" />
                    </template>
                </ul>
            </div>
        </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

const CatalogItemUnitDetails = defineAsyncComponent(() =>
  import('@/components/CatalogItemUnits/CatalogItemUnitDetails'))

export default {
    name: "Category",
    components: {
      CatalogItemUnitDetails,
    },
    created() {
        this.$store.dispatch("clearSingleCatalogItemUnit")
        this.$store.dispatch("getSingleCatalogItemUnit", this.$route.params.number)
    },
    computed: {
        ...mapGetters({
            catalogItemUnit: "catalogItemUnit"
        })
    },
    methods: {
        goBack() {
            this.$router.go(-1)
        },
    }
}
</script>